<template>
  <div class="navbar x-bc" v-loading="loading">
    <div class="left x-bc">
      <div class="x-f">
        <hamburger
          id="hamburger-container"
          :is-active="sidebar.opened"
          class="hamburger-container omit1"
          @toggleClick="toggleSideBar"
        />
        <breadcrumb
          id="breadcrumb-container"
          class="breadcrumb-container"
          v-if="!topNav"
        />
      </div>
      <!-- <span class="time">{{ timeArry.timeText }}</span> -->
    </div>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu x-f">
      <div class="ver-text x-fc">
        <span
          v-if="$store.state.user.userinfo.productServiceTime"
          class="operatePush fontS16"
          >{{ $store.state.user.userinfo.productServiceTime.productName || "" }}
        </span>
        <span v-if="$store.state.user.userinfo.productServiceTime"
          >{{ $store.state.user.userinfo.productServiceTime.versionName || "" }}
        </span>
        <span class="edition"></span>
        <span
          >{{ "商户号【" + $store.state.user.userinfo.tenantNo + "】" || "" }}
          {{ curTenant }}</span
        >
        <span class="edition"></span>
        <span v-if="$store.state.user.userinfo.productServiceTime"
          >有效期至：{{
            $store.state.user.userinfo.productServiceTime.serviceExpireTime || ""
          }}
        </span>
      </div>
      <div class="x-bc">
        <div class="operatingBtn x-f">
          <!-- @mouseenter="mouseenterSearch" -->
          <div class="btn-item search">
            <!--  v-if="isSearchFrame" -->
            <el-input
              size="mini"
              style="width: 200px"
              id="search"
              placeholder="搜索功能入口或帮助"
              prefix-icon="el-icon-search"
              v-model="selectVal"
              @blur="searchBlur"
              @focus="isSelectFrame = true"
              v-focus
            >
            </el-input>
            <!-- <div v-else>
            <span class="icon el-icon-search"></span>
            <span class="label">搜索</span>
          </div> -->
            <div v-show="isSelectFrame && selectVal" class="searchframe">
              <el-tabs v-model="searchTab">
                <el-tab-pane label="入口" name="entrance">
                  <div class="entranceList">
                    <el-scrollbar>
                      <div
                        class="entranceItem"
                        tabindex="0"
                        v-for="(item, index) in routerList"
                        :key="`${item.routerPath}.${index}`"
                        @click="routerSkip(item.routerPath)"
                      >
                        <div class="menuName left">{{ item.submenuName }}</div>
                        <div class="menuName right">{{ item.mainMenuName }}</div>
                      </div>
                    </el-scrollbar>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="帮助" name="help">帮助</el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="line"></div>
          <div class="btn-item">
            <span class="icon el-icon-service"></span>
            <span class="label">售后在线服务</span>
          </div>
          <div class="line"></div>
          <div class="btn-item">
            <span class="icon el-icon-warning-outline"></span>
            <span class="label">帮助中心</span>
          </div>
        </div>
        <div class="userInfo x-f marL10">
          <div class="username" style="display: inline-block">
            <span>欢迎您！</span>
            <span>{{ name }}</span>
          </div>
          <el-dropdown
            class="avatar-container right-menu-item hover-effect"
            trigger="click"
          >
            <div class="avatar-wrapper">
              <img :src="avatar" class="user-avatar" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item @click.native="setting = true">
                <span>布局设置</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="toTenant">商户管理</span>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { itmes } from "@/utils/index.js";
import { mapGetters, mapState } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    SizeSelect,
    Search,
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "avatar",
      "device",
      "name",
      "curTenant",
      "selectRouterList",
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    routerList() {
      if (!this.selectVal) {
        return this.selectRouterList;
      }
      //根据输入的值过滤下拉框数据
      const arr = this.selectRouterList.filter((item) => {
        return item.submenuName?.indexOf(this.selectVal) != -1;
      });
      return arr;
    },
    /*     routerList () {
      if (!this.selectVal) {
        return []
      }
      //根据输入的值过滤下拉框数据
      const arr = this.selectRouterList.filter(v => {
        let boolArr = []
        for (let key in v) {
          //判断对象里所有属性字是否包含输入的搜索值
          const bool = v[key].indexOf(this.selectVal) !== -1
          console.log(v[key])
          if (bool) boolArr.push(bool)
        }
        // 只要有一个包含就不过滤
        return boolArr.some(v => v)
      })
      return arr
      }, */
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
  },
  data() {
    return {
      loading: false,
      searchTab: "entrance", //搜索tab
      selectVal: "", //搜索的值
      isSearchFrame: false, //是否显示搜索框
      isSelectFrame: false, //是否显示下拉框
      cities: [
        {
          value: "Beijing",
          label: "北京",
        },
        {
          value: "Shanghai",
          label: "上海",
        },
        {
          value: "Nanjing",
          label: "南京",
        },
        {
          value: "Chengdu",
          label: "成都",
        },
        {
          value: "Shenzhen",
          label: "深圳",
        },
        {
          value: "Guangzhou",
          label: "广州",
        },
      ],
      value: "",
    };
  },
  created() {
    console.log("菜单", this.selectRouterList);
    // this.timeArry = itmes(new Date())
    // this.timeStart()
    // console.log('uer', this.$store.state.user.userinfo.tenantId)
  },
  methods: {
    //路由跳转
    routerSkip(routerPath) {
      this.$router.push(routerPath);
    },
    //鼠标移入搜索区域
    mouseenterSearch() {
      this.isSearchFrame = true;
    },
    //搜索框失去焦点
    searchBlur(event) {
      if (event.relatedTarget) {
        if (
          event.relatedTarget.classList.contains("el-tabs__item") ||
          event.relatedTarget.classList.contains("entranceItem")
        ) {
          return event.target.focus();
        }
      }
      if (this.selectVal) return (this.isSelectFrame = false);
      this.isSearchFrame = false;
    },
    //跳转创建商户页面的事件
    toTenant() {
      this.loading = true;
      this.$router.push("/creation/console");
      this.$store.commit("RESET_ROUTES");
      this.$store.commit("SET_TENANTID", "");
      this.$store.commit("SET_PRODUCTID", "");
      this.loading = false;
    },
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        this.timeArry = itmes(new Date());
      }, 1000);
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          try {
            this.$SOCKET.webSocketClose();
          } catch (e) {
            console.log("==", e);
          }
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/index";
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  min-width: 1500px;
  height: 50px;
  width: 100%;
  font-size: 14px;
  //min-width: 1400px;
  //overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .breadcrumb-container {
    /* 设置最大宽度，以便在超过这个宽度时显示省略号 */
    max-width: 100%; /* 你可以设置一个具体的值，如 300px，或者根据父元素的宽度动态计算 */

    /* 使用 text-overflow 属性来在文本溢出时显示省略号 */
    overflow: hidden;
    text-overflow: ellipsis;

    /* 你可以添加更多的样式，如边距、内边距、字体大小等 */
    white-space: nowrap; /* 防止文本换行 */
    font-size: 14px; /* 例如，设置字体大小 */
  }
  .el-breadcrumb {
    line-height: normal; /* 或者使用具体的值，如 1.5 */
  }
  .left,
  .right-menu {
    // width: 50%;
  }
  .ver-text {
    background-color: rgba(0, 0, 0, 0.025);
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 20px;
    .edition {
      display: inline-block;
      margin: 0 9px;
      width: 1px;
      background-color: #cccccc;
      height: 15px;
    }
  }
  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    height: 100%;
    justify-content: end;

    &:focus {
      outline: none;
    }
    .operatingBtn {
      width: 470px;
      display: flex;
      align-items: center;
      justify-content: end;
      .line {
        width: 1px;
        height: 10px;
        background-color: #bfbfbf;
        margin: 0 10px;
      }
      .btn-item {
        cursor: pointer;
        //搜索框
        &.search {
          position: relative;
          .el-input {
            width: 300px;
          }
          .searchframe {
            background-color: #fff;
            position: absolute;
            width: 350px;
            height: 400px;
            box-shadow: 0 2px 8px rgb(0 0 0 / 9%);
            ::v-deep .el-tabs__nav {
              width: 100%;
            }
            ::v-deep .el-tabs__header {
              margin: 0;
            }
            ::v-deep .el-tabs__item {
              width: 50%;
              text-align: center;
              padding: 0;
            }
            .entranceList {
              height: 355px;
              //滚动条
              .el-scrollbar {
                height: 100%;
                overflow: hidden;
                ::v-deep .el-scrollbar__wrap {
                  overflow-x: hidden;
                }
              }
              .entranceItem {
                display: flex;
                padding: 0 20px;
                height: 35px;
                align-items: center;
                font-size: 16px;
                &:hover {
                  color: #409eff;
                  background-color: #eef2fe;
                }
                .menuName {
                  width: 50%;
                  &.left {
                    text-align: left;
                  }
                  &.right {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
        .label {
          margin-left: 2px;
        }
      }
    }
    .userInfo {
      display: flex;
      justify-content: end;
      .username {
        color: #409eff;
        margin-right: 10px;
      }
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      height: 50px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
  ::v-deep .el-dropdown-menu {
    background-color: #fff;
  }
}
</style>
