var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "navbar x-bc",
    },
    [
      _c("div", { staticClass: "left x-bc" }, [
        _c(
          "div",
          { staticClass: "x-f" },
          [
            _c("hamburger", {
              staticClass: "hamburger-container omit1",
              attrs: {
                id: "hamburger-container",
                "is-active": _vm.sidebar.opened,
              },
              on: { toggleClick: _vm.toggleSideBar },
            }),
            !_vm.topNav
              ? _c("breadcrumb", {
                  staticClass: "breadcrumb-container",
                  attrs: { id: "breadcrumb-container" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c("div", { staticClass: "right-menu x-f" }, [
        _c("div", { staticClass: "ver-text x-fc" }, [
          _vm.$store.state.user.userinfo.productServiceTime
            ? _c("span", { staticClass: "operatePush fontS16" }, [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.user.userinfo.productServiceTime
                      .productName || ""
                  ) + " "
                ),
              ])
            : _vm._e(),
          _vm.$store.state.user.userinfo.productServiceTime
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.user.userinfo.productServiceTime
                      .versionName || ""
                  ) + " "
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "edition" }),
          _c("span", [
            _vm._v(
              _vm._s(
                "商户号【" + _vm.$store.state.user.userinfo.tenantNo + "】" ||
                  ""
              ) +
                " " +
                _vm._s(_vm.curTenant)
            ),
          ]),
          _c("span", { staticClass: "edition" }),
          _vm.$store.state.user.userinfo.productServiceTime
            ? _c("span", [
                _vm._v(
                  "有效期至：" +
                    _vm._s(
                      _vm.$store.state.user.userinfo.productServiceTime
                        .serviceExpireTime || ""
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "x-bc" }, [
          _c("div", { staticClass: "operatingBtn x-f" }, [
            _c(
              "div",
              { staticClass: "btn-item search" },
              [
                _c("el-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticStyle: { width: "200px" },
                  attrs: {
                    size: "mini",
                    id: "search",
                    placeholder: "搜索功能入口或帮助",
                    "prefix-icon": "el-icon-search",
                  },
                  on: {
                    blur: _vm.searchBlur,
                    focus: function ($event) {
                      _vm.isSelectFrame = true
                    },
                  },
                  model: {
                    value: _vm.selectVal,
                    callback: function ($$v) {
                      _vm.selectVal = $$v
                    },
                    expression: "selectVal",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSelectFrame && _vm.selectVal,
                        expression: "isSelectFrame && selectVal",
                      },
                    ],
                    staticClass: "searchframe",
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        model: {
                          value: _vm.searchTab,
                          callback: function ($$v) {
                            _vm.searchTab = $$v
                          },
                          expression: "searchTab",
                        },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "入口", name: "entrance" } },
                          [
                            _c(
                              "div",
                              { staticClass: "entranceList" },
                              [
                                _c(
                                  "el-scrollbar",
                                  _vm._l(
                                    _vm.routerList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.routerPath + "." + index,
                                          staticClass: "entranceItem",
                                          attrs: { tabindex: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.routerSkip(
                                                item.routerPath
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "menuName left" },
                                            [_vm._v(_vm._s(item.submenuName))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "menuName right" },
                                            [_vm._v(_vm._s(item.mainMenuName))]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "帮助", name: "help" } },
                          [_vm._v("帮助")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "line" }),
            _vm._m(0),
            _c("div", { staticClass: "line" }),
            _vm._m(1),
          ]),
          _c(
            "div",
            { staticClass: "userInfo x-f marL10" },
            [
              _c(
                "div",
                {
                  staticClass: "username",
                  staticStyle: { display: "inline-block" },
                },
                [
                  _c("span", [_vm._v("欢迎您！")]),
                  _c("span", [_vm._v(_vm._s(_vm.name))]),
                ]
              ),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar },
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/user/profile" } },
                        [_c("el-dropdown-item", [_vm._v("个人中心")])],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              _vm.setting = true
                            },
                          },
                        },
                        [_c("span", [_vm._v("布局设置")])]
                      ),
                      _c("el-dropdown-item", [
                        _c("span", { on: { click: _vm.toTenant } }, [
                          _vm._v("商户管理"),
                        ]),
                      ]),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("退出登录")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-item" }, [
      _c("span", { staticClass: "icon el-icon-service" }),
      _c("span", { staticClass: "label" }, [_vm._v("售后在线服务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-item" }, [
      _c("span", { staticClass: "icon el-icon-warning-outline" }),
      _c("span", { staticClass: "label" }, [_vm._v("帮助中心")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }